// ** @jsx jsx *
import React, { useState } from "react"

import { jsx } from "theme-ui"
const LimitText = ({ text = "", limitText = 100, noToggle }) => {
  let validateText
  if (!text) {
    validateText = ""
    // console.warn("El texto introducido en LimitText está vació o no es válido")
  } else {
    validateText = text
  }
  const [toggleText, setToggleText] = useState(false)
  return (
    <>
      {validateText.length > limitText ? (
        <>
          <>{toggleText ? text : `${validateText.substr(0, limitText)}`}</>
          {!toggleText && (
            <span
              onClick={() => !noToggle && setToggleText(true)}
              className="text-info"
              sx={{
                cursor: "pointer",
                color: "primary",
              }}
            >
              {`...${noToggle ? "" : "ver más"}`}
            </span>
          )}
          {toggleText && (
            <span
              onClick={() => setToggleText(false)}
              className="text-info"
              sx={{
                cursor: "pointer",
                color: "primary",
              }}
            >
              {" "}
              ver menos
            </span>
          )}
        </>
      ) : (
        validateText
      )}
    </>
  )
}

export default LimitText
