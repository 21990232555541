// ** @jsx jsx *
import React, { useContext, useState, useEffect } from "react"
import SendButton from "./ui/SendButton"
import { CartContext, DataGetContext } from "../components/context/Context"
import { jsx, Text, Input, Label, Select } from "theme-ui"
import { useStaticQuery, graphql } from "gatsby"
import messageWhatsapp from "../lib/messageWhatsapp"
import SheetDB from "sheetdb-js"
import whatsappApi from "../lib/whatsappApi"
import ReactPixel from "../lib/react-facebook-pixel"
const Form = ({ linkCartShare, insideModal, productOnly }) => {
  const { cart, setCart } = useContext(CartContext)
  const { dataGet, setDataGet } = useContext(DataGetContext)
  const data = useStaticQuery(graphql`
    query Form {
      sanitySetting {
        whatsapp {
          phone
          whatsappExtra
        }
        money
      }
      sanityIntegration {
        sheetDb {
          active
          id
          sheet
        }
        facebookPixel {
          pixelId
          currency
        }
      }
      sanityFormSetting {
        form {
          field {
            ... on SanityFormInputText {
              _key
              _type
              required
              title
            }
            ... on SanityFormOptions {
              _key
              _type
              limit
              listOptions
              required
              title
            }
          }
        }
        cupon {
          field {
            ... on SanityCuponPercent {
              _key
              _type
              code
              percent
            }
            ... on SanityCuponMount {
              _key
              _type
              code
              mount
            }
          }
        }
        formBasic {
          name
        }
      }
    }
  `)
  const { sanityFormSetting } = data
  const validationSanityForm =
    sanityFormSetting &&
    sanityFormSetting.form &&
    sanityFormSetting.form.field &&
    sanityFormSetting.form.field.length > 0
  const [userData, setUserData] = useState({
    name: "",
  })
  const [cuponText, setCuponText] = useState("")
  const [cuponMatch, setCuponMatch] = useState(false)
  const [whatsappNumber, setWhatsappNumber] = useState(
    `${data.sanitySetting.whatsapp.phone.replace(/ |\+|-/g, "")}`
  )
  const [messageExtra, setMessageExtra] = useState("")
  const [send, setSend] = useState(false)
  const [activeSheetDb, setActiveSheetDb] = useState(
    data.sanityIntegration
      ? data.sanityIntegration.sheetDb
        ? data.sanityIntegration.sheetDb.active
        : false
      : false
  )
  const { money } = data.sanitySetting
  const message = messageWhatsapp({
    cart,
    userData,
    money,
    messageExtra,
    productOnly,
    cupon: cuponMatch,
  })
  console.log(message)
  const whatsappSubmit = () => {
    // ReactPixel.track("CompleteRegistration", {
    //   value: totalPrice,
    //   currency: facebookPixel && facebookPixel.currency,
    // })
    setSend(false)
    if (typeof window != "undefined") {
      console.log(whatsappApi(whatsappNumber, message))
      window.open(whatsappApi(whatsappNumber, message), "_self")
    }
  }

  const submit = () => {
    let pedido = []
    const now = new Date()

    cart.forEach(({ order }, i) => {
      order.forEach(
        (
          {
            title,
            variants,
            categories,
            tag,
            count,
            price,
            sku,
            id,
            otherOptionsProduct,
          },
          i
        ) => {
          let pedidoItem = {
            "id pedido": now.getTime(),
            fecha: `${now.getDate()}/${
              now.getMonth() + 1
            }/${now.getFullYear()}`,
            hora: `${now.getHours()}:${now.getMinutes()}:${now.getSeconds()}`,
            sku,
            producto: title,
            "diferenciador variante": variants ? variants.name : null,
            variante: tag,
            precio: price,
            cantidad: count,
            categorias:
              categories && categories.length > 0
                ? categories.map(({ title }) => title).toString()
                : "",
            "nombre cliente": userData.name,
            "whatsapp receptor": whatsappNumber,
            nota: messageExtra,
            oculto:
              (otherOptionsProduct && otherOptionsProduct.hiddenData) || "",
            cupon: cuponMatch && cuponMatch.code,
            ...userData,
          }
          pedido.push(pedidoItem)
        }
      )
    })
    // console.log(pedido)
    SheetDB.write(
      `https://sheetdb.io/api/v1/${data.sanityIntegration.sheetDb.id}`,
      {
        sheet: data.sanityIntegration.sheetDb.sheet,
        data: pedido,
      }
    )

      .then(function (result) {
        whatsappSubmit()
        if (result.error) {
          console.error(result)
        }
      })
      .catch(error => {
        console.error(error)
        whatsappSubmit()
      })
  }

  useEffect(() => {
    const { whatsappExtra } = data.sanitySetting.whatsapp

    setMessageExtra(dataGet.message)
    const newWhatsappNumber = whatsappExtra.find(w => w == dataGet.phone)
    newWhatsappNumber && setWhatsappNumber(newWhatsappNumber)

    if (data.sanityIntegration) {
      if (data.sanityIntegration.sheetDb) {
        if (data.sanityIntegration.sheetDb.active) {
          setActiveSheetDb(true)
        }
      }
    }
    if (typeof window !== "undefined") {
      const localStorageUserData = JSON.parse(
        window.localStorage.getItem("userData")
      )
      if (typeof localStorageUserData == "object") {
        if (validationSanityForm) {
          let newUserData = { ...userData }
          try {
            Object.keys(localStorageUserData).forEach((key, i) => {
              sanityFormSetting.form.field.forEach(({ title }) => {
                if (key == title) {
                  newUserData[key] = localStorageUserData[key]
                }
              })
            })
            if (localStorageUserData.name) {
              newUserData.name = localStorageUserData.name
            }
            setUserData(newUserData)
          } catch (error) {
            console.error(
              "Ocurrió un error al intentar recuperar los datos del cliente"
            )
            console.error(error)
          }
        }
      }
    }
    if (validationSanityForm) {
      let pushUserData = { ...userData }
      sanityFormSetting.form.field.forEach((ask, i) => {
        pushUserData[ask.title] = ""
      })
    }
  }, [])
  useEffect(() => {
    if (send) {
      if (typeof window !== "undefined") {
        if (
          data.sanityIntegration &&
          data.sanityIntegration.facebookPixel &&
          data.sanityIntegration.facebookPixel &&
          data.sanityIntegration.facebookPixel.pixelId
        ) {
          ReactPixel.track("CompleteRegistration", {
            value: (() => {
              let _totalPrice = 0
              if (cart && cart.length > 0) {
                cart.forEach(({ totalPrice }) => {
                  _totalPrice += totalPrice
                })
              }
              return _totalPrice
            })(),
            quantity: (() => {
              let _totalCount = 0
              if (cart && cart.length > 0) {
                cart.forEach(({ totalCount }) => {
                  _totalCount += totalCount
                })
              }
              return _totalCount
            })(),
            currency: data.sanityIntegration.facebookPixel.currency,
          })
        }
      }
      if (activeSheetDb) {
        submit()
      } else {
        whatsappSubmit()
      }
      setTimeout(() => {
        setSend(false)
        setActiveSheetDb(false)
      }, 10000)
    }
  }, [send])
  useEffect(() => {
    if (typeof window !== "undefined") {
      window.localStorage.setItem("userData", JSON.stringify(userData))
    }
  }, [userData])
  let renderCupon = false

  try {
    renderCupon =
      data.sanityFormSetting.cupon &&
      data.sanityFormSetting.cupon.field.length > 0
  } catch (error) {}
  useEffect(() => {
    if (renderCupon) {
      for (let i = 0; i < data.sanityFormSetting.cupon.field.length; i++) {
        const cupon = data.sanityFormSetting.cupon.field[i]
        if (`${cupon.code}`.toLowerCase() == `${cuponText}`.toLowerCase()) {
          setCuponMatch(cupon)
          break
        } else {
          setCuponMatch(false)
        }
      }
    }
  }, [cuponText])
  return (
    <form
      onSubmit={e => {
        e.preventDefault()
        setSend(true)
      }}
      sx={{ position: "relative" }}
    >
      <div
        sx={{
          variant: "div.flex",
          justifyContent: "center",
          height: "100%",
          width: "-webkit-fill-available",
          borderTop: insideModal ? "" : "simple",
          padding: 2,
          paddingLeft: 0,
          paddingRight: 0,
        }}
      >
        <div sx={{ maxWidth: 300, paddingTop: 2 }}>
          <Label
            sx={{
              variant: "div.flexColumn",
              alignItems: insideModal ? "flex-start" : "",
              marginTop: 3,
              marginBottom: 3,
            }}
          >
            <Text variant="primary">Nombre</Text>
            <Input
              value={userData.name}
              onChange={e => setUserData({ ...userData, name: e.target.value })}
              sx={{ width: 250 }}
              type="text"
              placeholder=""
            />
          </Label>
          {validationSanityForm && (
            <>
              {sanityFormSetting.form.field.map(ask => {
                if (ask._type == "formInputText") {
                  return (
                    <Label
                      sx={{
                        variant: "div.flexColumn",
                        alignItems: insideModal ? "flex-start" : "",
                        marginTop: 3,
                        marginBottom: 3,
                      }}
                    >
                      <Text variant="primary">{ask.title}</Text>
                      <Input
                        value={userData[ask.title]}
                        onChange={e =>
                          setUserData({
                            ...userData,
                            [ask.title]: e.target.value,
                          })
                        }
                        required={ask.required || false}
                        sx={{ width: 250 }}
                        type="text"
                        placeholder=""
                      />
                    </Label>
                  )
                }
                if (ask._type == "formOptions") {
                  return (
                    <Options
                      ask={ask}
                      formData={userData}
                      setFormData={setUserData}
                    />
                  )
                }
              })}
            </>
          )}
          {renderCupon && (
            <Label
              sx={{
                variant: "div.flexColumn",
                alignItems: insideModal ? "flex-start" : "",
                marginTop: 3,
                marginBottom: 3,
              }}
            >
              <Text variant="primary">Código de descuento</Text>
              <Input
                value={cuponText}
                onChange={e => setCuponText(e.target.value)}
                sx={{ width: 250 }}
                type="text"
                placeholder=""
              />
            </Label>
          )}
        </div>
      </div>

      <SendButton insideModal={insideModal} send={send} cupon={cuponMatch} />
    </form>
  )
}
const Options = ({ ask, formData, setFormData }) => {
  const validateAsk = ask.limit && ask.listOptions && ask.listOptions.length > 1
  const [check, setCheck] = useState([])
  const [value, setValue] = useState(validateAsk ? ask.listOptions[0] : "")
  const [disabled, setDisabled] = useState(false)
  useEffect(() => {
    if (validateAsk) {
      setCheck(ask.listOptions.map(op => ({ name: op, value: false })))
    }
  }, [])
  useEffect(() => {
    setFormData({ ...formData, [ask.title]: value })
  }, [value])
  useEffect(() => {
    setFormData({
      ...formData,
      [ask.title]: check
        .filter(({ value }) => value)
        .map(({ name }) => name)
        .toString(),
    })
  }, [check])

  return (
    validateAsk && (
      <div
        sx={{
          variant: "div.flexColumn",
          alignItems: "flex-start",
          marginTop: 3,
          marginBottom: 3,
        }}
      >
        {ask.title && (
          <Text sx={{ marginBottom: 2 }} variant="caption">
            {ask.title}:
          </Text>
        )}
        {ask.limit == 1 ? (
          <Select
            sx={{ width: "max-content" }}
            value={value}
            onChange={e => setValue(e.target.value)}
          >
            {ask.listOptions.map(op => {
              return <option>{op}</option>
            })}
          </Select>
        ) : (
          check.length > 0 && (
            <div sx={{ variant: "div.flex" }}>
              {ask.listOptions.map((op, i) => {
                return (
                  <CheckboxComponent
                    op={op}
                    check={check[i]}
                    disabled={disabled}
                    setCheck={checked => {
                      let newCheck = [...check]
                      newCheck[i] = { ...newCheck[i], value: checked }
                      const countCheck = check.filter(
                        ({ value }) => value == true
                      ).length
                      setCheck(newCheck)
                      if (checked && countCheck > ask.limit - 2) {
                        setDisabled(true)
                      } else {
                        setDisabled(false)
                      }
                    }}
                  />
                )
              })}
            </div>
          )
        )}
      </div>
    )
  )
}
const CheckboxComponent = ({ op, check, setCheck, disabled }) => {
  return (
    <Label
      sx={{
        cursor: "pointer",
        variant: "div.flex",
        marginRight: 3,
        boxShadow: check.value ? "pressedSmall" : disabled ? "" : "flatSmall",
        opacity: !check.value && disabled ? "0.5" : "",

        borderRadius: 1,
        paddingTop: 1,
        paddingBottom: 1,
        paddingLeft: 2,
        paddingRight: 3,
      }}
    >
      <input
        disabled={!check.value && disabled}
        type="checkbox"
        name={op}
        checked={check.value}
        onChange={e => {
          setCheck(e.target.checked)
        }}
      />
      <Text variant="small">{op}</Text>
    </Label>
  )
}

export default Form
