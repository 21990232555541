// ** @jsx jsx *
import React, { useState, useEffect, useContext } from "react"
import { jsx, Styled, Text, Button, IconButton } from "theme-ui"
import search from "../../lib/search"
import LimitText from "../../widgets/LimitText.jsx"
import Controls from "./Controls.jsx"
import Variants from "./Variants.jsx"
import FormProduct from "./FormProduct.jsx"
import ImagesProduct from "./ImagesProduct.jsx"
import CategoriesSnacks from "../CategoriesSnacks.jsx"
import { CartContext } from "../context/Context.jsx"

import countapi from "countapi-js"
import slug from "../../lib/slug"
import { useStaticQuery, graphql } from "gatsby"
import Body from "./Body"
import Money from "../../lib/Money.jsx"
import Share from "./Share"
const ProductMaster = ({
  product,
  onComplete,
  initialCount = 1,
  overwriteSend,
  overwriteRemove,
  insideModal,
}) => {
  const { sanitySetting, sanityUserInterface } = useStaticQuery(graphql`
    query ProductMaster {
      sanitySetting {
        money
      }
      sanityUserInterface {
        cartOptions {
          activeCart
        }
      }
    }
  `)
  const { money } = sanitySetting
  const activeCart =
    sanityUserInterface == null
      ? true
      : sanityUserInterface.cartOptions == null
      ? true
      : sanityUserInterface.cartOptions.activeCart
  const hiddenAddQuantity =
    product &&
    product.quantityOptions &&
    product.quantityOptions.hiddenAddQuantity

  const _initialCount = hiddenAddQuantity
    ? 0
    : product &&
      product.variants &&
      product.variants.list &&
      product.variants.list.length > 0
    ? 0
    : initialCount
  //context
  const { cart, setCart } = useContext(CartContext)

  //states
  const [select, setSelect] = useState(0)
  const [url, setUrl] = useState("")
  const [variants, setVariants] = useState([
    { ...product, count: _initialCount },
  ])
  const [formData, setFormData] = useState([])
  const validateFormProduct =
    product.formProduct &&
    product.formProduct.asks &&
    product.formProduct.asks.length > 0
  const setVariantIndex = (i, q) => {
    let newData = [...variants]
    newData[i] = { ...newData[i], count: q }
    setVariants(newData)
  }
  useEffect(() => {
    if (
      typeof window !== "undefined" &&
      window.location &&
      window.location.hostname
    ) {
      setUrl(`${window.location.origin}/${product.slug}`)

      countapi
        .hit(slug(window.location.hostname), slug(product.id))
        .then(result => {
          console.log(result.value)
        })
        .catch(e => console.log(e))
    }
    if (validateFormProduct) {
      let newFormData = [...formData]
      product.formProduct.asks
        .filter(ask => ask && ask._type !== "productsExtra")
        .forEach((ask, i) => {
          if (ask && ask._type !== "productsExtra") {
            newFormData.push({
              ...ask,
              index: i,
              name: ask.title,
              value:
                (ask._type == "options" &&
                  ask.listOptions &&
                  ask.listOptions.length > 1 &&
                  ask.limit == 1 &&
                  ask.listOptions[0]) ||
                "",
            })
          }
        })

      setFormData(newFormData)
    }
  }, [])
  useEffect(() => {
    const variantProcessor = product => {
      let newVariants = [{ ...product, count: _initialCount }]
      if (product.variants) {
        newVariants = [
          {
            ...product,
            tag: product.variants.tag,
            name: product.variants.name,
            count: _initialCount,
          },
        ]

        product.variants.list.forEach(variant => {
          let ProcessedVariant = {}
          Object.keys(variant).forEach((key, i) => {
            if (Object.values(variant)[i])
              ProcessedVariant[key] = Object.values(variant)[i]
          })
          newVariants.push({
            ...newVariants[0],
            ...ProcessedVariant,
            count: 0,
          })
        })
      }
      if (validateFormProduct) {
        const productsExtra = product.formProduct.asks.filter(
          ({ _type }) => _type == "productsExtra"
        )
        if (productsExtra.length > 0) {
          productsExtra.forEach(({ _key, _type, title, extra }) => {
            if (extra && extra.length > 0) {
              extra.forEach((product, i) => {
                const index = newVariants.length
                newVariants.push({
                  ...newVariants[0],
                  name: title,
                  tag: product.title,
                  hiddenExtra: true,
                  count: 0,
                  index,
                  extraId: product.id,
                })
              })
            }
          })
        }
      }
      if (cart.length > 0) {
        const matchCart = search(cart, "id", product.id)
        if (matchCart) {
          matchCart.order.forEach((variant, i) => {
            if (variant.tag) {
              const matchVariant = search(newVariants, "tag", variant.tag)
              if (matchVariant) {
                newVariants[matchVariant.index] = variant
              }
            } else {
              newVariants[0] = variant
            }
          })
        }
      }
      return newVariants
    }
    setVariants(variantProcessor(product))
  }, [cart])
  const insideModalStyle = insideModal
    ? {
        minHeight: [300, 320, 380],
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
      }
    : {}
  return (
    <div
      sx={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        alignItems: "center",
        paddingBottom: "70px",
      }}
    >
      <div
        sx={{
          maxWidth: ["width1", "width2", "width3"],
          display: "flex",
          flexDirection: ["column", "row"],
          justifyContent: ["initial", "space-between"],
          alignItems: ["initial", "flex-start"],
        }}
      >
        {variants.map(({ img, multimediaOptions }, i) => {
          let images = img ? [img] : []

          if (multimediaOptions) {
            if (multimediaOptions.listImages) {
              multimediaOptions.listImages.forEach(image => {
                images.push(image)
              })
            }
          }

          return (
            images.length > 0 &&
            select == i && (
              <ImagesProduct
                product={product}
                key={`images-product-${i}`}
                images={images}
                multimediaOptions={multimediaOptions}
                // big={variants.length > 1}
              />
            )
          )
        })}
        {/* <ImagesProduct images={[variants[0].img]} big={variants.length > 1} /> */}
        <div
          sx={{
            width: ["width1", "halfWidth2", "halfWidth3"],
            padding: 2,
            ...insideModalStyle,
          }}
        >
          <div>
            <Title product={variants[select]} money={money} />
            <Description product={variants[select]} />

            <Variants
              variants={variants}
              select={select}
              setSelect={setSelect}
            />

            <FormProduct
              product={product}
              money={money}
              extrasCount={variants.filter(({ hiddenExtra }) => hiddenExtra)}
              setVariantIndex={setVariantIndex}
              formData={formData}
              setFormData={setFormData}
            />
          </div>

          <Controls
            product={product}
            formData={formData}
            money={money}
            variants={variants}
            setVariants={setVariants}
            select={select}
            cart={cart}
            setCart={setCart}
            activeCart={activeCart}
            onComplete={onComplete}
            overwriteSend={overwriteSend}
            overwriteRemove={overwriteRemove}
            insideModal={insideModal}
          />
        </div>
      </div>
      <Share url={url} />
      <Body document={product} variants={variants} select={select} />

      {product.categories && product.categories.length > 0 && (
        <div
          sx={{
            variant: "div.flex",
            justifyContent: "center",
            width: "100%",
            marginTop: 2,
          }}
        >
          <div sx={{ width: ["width1", "width2", "width3"] }}>
            <Text variant="caption" mb={1}>
              Productos similares:
            </Text>
            <div
              sx={{
                variant: "div.flex",
                flexWrap: "wrap",
                border: "simple",
                borderRight: 0,
                borderTop: 0,
                borderBottom: 0,
              }}
            >
              <CategoriesSnacks newCategories={product.categories} />
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

const Title = ({ product, money }) => {
  const priceNotReduced =
    product.discount &&
    product.discount.active &&
    product.discount.priceNotReduced

  return (
    <div
      sx={{
        alignItems: "center",
        display: "flex",
        marginTop: 3,
      }}
    >
      <Styled.h1 sx={{ margin: 0 }}>{product.title}</Styled.h1>

      <div
        sx={{
          marginLeft: 2,
          borderRadius: 2,
          backgroundColor: "secondary",
          padding: 2,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          fontFamily: "body",
          color: "textSecondary",
          fontWeight: "bold",
        }}
      >
        {Money(product.price)}
      </div>
      {priceNotReduced && (
        <div
          sx={{
            marginLeft: 2,
            borderRadius: 2,
            padding: 1,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            fontFamily: "body",
            fontSize: 1,
            textDecoration: "line-through",
            opacity: 0.5,
          }}
        >
          {Money(priceNotReduced)}
        </div>
      )}
    </div>
  )
}
const Description = ({ product }) => {
  return (
    <div sx={{ marginTop: 1, marginBottom: 3 }}>
      <Styled.p sx={{ margin: 0 }}>
        <LimitText text={product.description} />
      </Styled.p>
    </div>
  )
}

export default ProductMaster
