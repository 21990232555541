// ** @jsx jsx *
import React, { useContext } from "react"
import { jsx, Spinner, Button } from "theme-ui"
import { SiWhatsapp } from "react-icons/si"
import { CartContext } from "../context/Context"
import TotalCount from "../TotalCount"
const SendButton = ({ onClick, send, insideModal, cupon }) => {
  const { cart, setCart } = useContext(CartContext)

  return (
    <>
      {insideModal ? (
        <Button
          type="submit"
          variant="whatsapp"
          sx={{ width: "100%", marginTop: 3 }}
        >
          {!send ? (
            <>
              <SiWhatsapp sx={{ fontSize: 4, marginRight: 1 }} />
              Enviar
            </>
          ) : (
            <Spinner sx={{ color: "whatsappText" }} />
          )}
        </Button>
      ) : (
        <>
          <button
            type="submit"
            sx={{
              position: "fixed",
              cursor: "pointer",
              bottom: 0,
              width: "100vw",
            }}
          >
            <div
              sx={{
                position: "relative",
                height: 5,
                width: "100%",
                backgroundColor: "whatsappBackground",
                display: "flex",
                justifyContent: "center",
                alignItems: "flex-end",
                border: "whatsappText",
                borderLeft: 0,
                borderRight: 0,
                borderBottom: 0,
              }}
            >
              <div
                sx={{
                  position: "absolute",
                  top: -50,
                  left: "50%",
                  transform: "translate(-50%)",
                  borderRadius: "16px 16px 0 0",
                  width: 150,
                  backgroundColor: "whatsappBackground",
                  height: 50,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {!send ? (
                  <>
                    <SiWhatsapp
                      sx={{
                        color: "whatsappText",
                        fontSize: 3,
                        marginRight: 1,
                      }}
                    />
                    <h5 sx={{ fontFamily: "body", color: "whatsappText" }}>
                      Enviar pedido
                    </h5>
                  </>
                ) : (
                  <Spinner sx={{ color: "whatsappText" }} />
                )}
              </div>
              <div
                sx={{
                  position: "absolute",
                  top: -40,
                  left: ["74vw", "width2", "width3"],
                }}
              >
                <TotalCount cupon={cupon} />
              </div>
            </div>
          </button>
          <style jsx="true" global="true">{`
            body {
              padding-bottom: 70px;
            }
          `}</style>
        </>
      )}
    </>
  )
}

export default SendButton
