// ** @jsx jsx *
import React, { useState, useEffect } from "react"
import { jsx, Box } from "theme-ui"
import { BiSearchAlt2 } from "react-icons/bi"
import { IoMdClose } from "react-icons/io"
import CategoriesSnacks from "../CategoriesSnacks"
import { Link } from "gatsby"
import Modal from "./Modal"
import SearchApp from "../SearchApp"
const Header = ({ prevPage }) => {
  const [openSearch, setOpenSearch] = useState(false)
  const [hiddenMenu, setHiddenMenu] = useState(false)
  useEffect(() => {
    if (typeof window !== "undefined") {
      const url = new URL(window.location.href)
      url.searchParams.get("hiddenMenu") !== null && setHiddenMenu(true)
    }
  }, [])
  return (
    !hiddenMenu && (
      <div
        sx={{
          display: "flex",
          justifyContent: "space-around",
          alignItems: "center",
          maxWidth: "99.9vw",
        }}
      >
        <Box p={3} onClick={() => setOpenSearch(true)}>
          <BiSearchAlt2
            className="pointer"
            sx={{ fontSize: 5, fill: "text" }}
          />
        </Box>
        <CategoriesSnacks overflowX />
        {prevPage && (
          <Box as={Link} to={prevPage} p={3}>
            <IoMdClose
              className="pointer"
              sx={{ fontSize: 5, fill: "text", color: "text" }}
            />
          </Box>
        )}
        <Modal
          open={openSearch}
          onClose={() => setOpenSearch(false)}
          closeIcon
          justifyContent="flex-start"
        >
          <SearchApp />
        </Modal>
      </div>
    )
  )
}

export default Header
