// ** @jsx jsx *
import React, { useContext } from "react"
import { jsx } from "theme-ui"
import { Link } from "gatsby"
import { DataGetContext } from "../context/Context.jsx"
const LinkDataGet = props => {
  const { dataGet, setDataGet } = useContext(DataGetContext)

  return (
    <Link {...props} to={`${props.to}${dataGet.search}`}>
      {props.children}
    </Link>
  )
}

export default LinkDataGet
