// ** @jsx jsx *
import React, { useContext, useState, useEffect } from "react"
import { jsx, Text } from "theme-ui"
import { CartContext } from "../components/context/Context"
import { useStaticQuery, graphql } from "gatsby"
import Money from "../lib/Money.jsx"
const TotalCount = ({ cupon }) => {
  const { sanitySetting } = useStaticQuery(graphql`
    query TotalPrice {
      sanitySetting {
        money
      }
    }
  `)
  const { cart } = useContext(CartContext)
  const money = sanitySetting.money || "$"
  const [totalPrice, setTotalPrice] = useState(0)
  const [error, setError] = useState(false)
  // console.log(cupon)
  useEffect(() => {
    try {
      if (cart && Array.isArray(cart)) {
        let newPrice = 0
        cart.forEach(({ totalPrice }) => {
          if (totalPrice) {
            newPrice += totalPrice
          }
        })
        setTotalPrice(newPrice)
      }
    } catch (error) {
      setError(true)
    }
  }, [cart])
  return (
    !error && (
      <Text
        variant="tag"
        sx={{
          width: "max-content",
          display: "flex",
        }}
      >
        {cupon ? (
          <>
            {cupon._type == "cuponMount" ? (
              <>
                <div
                  sx={{
                    fontWeight: "normal",
                    position: "absolute",
                    top: -20,
                    fontSize: 0,
                  }}
                >
                  {Money(totalPrice)} - {Money(cupon.mount)} ={" "}
                </div>
                <div sx={{ color: "primary" }}>
                  💰{Money(totalPrice - cupon.mount)}
                </div>
              </>
            ) : (
              <>
                <div
                  sx={{
                    fontWeight: "normal",
                    position: "absolute",
                    top: -20,
                    fontSize: 0,
                  }}
                >
                  {Money(totalPrice)} - {cupon.percent}% ={" "}
                </div>
                <div sx={{ color: "primary" }}>
                  💰 {Money(totalPrice - (cupon.percent / 100) * totalPrice)}
                </div>
              </>
            )}
          </>
        ) : (
          <>💰{Money(totalPrice)}</>
        )}
      </Text>
    )
  )
}

export default TotalCount
