// ** @jsx jsx *
import React, { useState } from "react"
import { jsx, Styled, Text, Button, IconButton } from "theme-ui"
import { CopyToClipboard } from "react-copy-to-clipboard"
import { BiCopy } from "react-icons/bi"
const Share = ({ url }) => {
  const [copied, setCopied] = useState(false)

  return (
    <div
      sx={{
        variant: "div.flex",
        marginTop: 1,
        marginBottom: 1,
        width: ["width1", "width2", "width3"],
      }}
    >
      <CopyToClipboard text={url} onCopy={() => setCopied(true)}>
        <button
          sx={{ variant: copied ? "buttons.primary" : "buttons.outlined" }}
        >
          <BiCopy />
          {copied && "Enlace copiado"}
        </button>
      </CopyToClipboard>
    </div>
  )
}

export default Share
