// ** @jsx jsx *
import React, { useState } from "react"
import { useStaticQuery, graphql } from "gatsby"
import { useFlexSearch } from "react-use-flexsearch"
import { IoMdClose } from "react-icons/io"
import { jsx, Input, Text, Message } from "theme-ui"
import Product from "./Product"
const SearchApp = () => {
  const [query, setQuery] = useState("")
  const { sanitySetting, localSearchProducts } = useStaticQuery(graphql`
    query ProductsSearchApp {
      sanitySetting {
        money
      }
      localSearchProducts {
        index
        id
        store
      }
    }
  `)
  const { store, index } = localSearchProducts
  const { money } = sanitySetting
  const results = useFlexSearch(query, index, store, {
    limit: 15,
  })
  return (
    <div sx={{ marginTop: 2 }}>
      <div sx={{ variant: "div.flex", marginBottom: 3 }}>
        {query && (
          <div onClick={() => setQuery("")}>
            <IoMdClose sx={{ fontSize: 4, marginRight: 2 }} />
          </div>
        )}
        <Input
          type="text"
          placeholder=" 🔍 Busca lo que quieras"
          value={query}
          onChange={e => {
            setQuery(e.target.value)
          }}
          sx={{ boxShadow: "pressed" }}
        />
      </div>
      <div
        sx={{
          overflowY: "auto",
          minHeight: "60vh",
          maxHeight: "65vh",
          variant: "div.flexColumn",

          justifyContent: query.length == 0 ? "center" : "flex-start",
        }}
      >
        {results.map((result, i) => {
          return (
            <div key={i} sx={{ marginBottom: 1 }}>
              <Product money={money} product={result} horizontal modal />
            </div>
          )
        })}
        <>
          {results.length == 0 && (
            <div
              sx={{
                height: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {query.length == 0 ? (
                <Text variant="primary" sx={{ fontSize: "25px" }}>
                  {" "}
                  🔍
                </Text>
              ) : (
                <Message variant="primary" sx={{ fontSize: "20px" }}>
                  No hay resultados para <strong>{query}</strong>
                </Message>
              )}
            </div>
          )}
        </>
      </div>
    </div>
  )
}

export default SearchApp
