// ** @jsx jsx *
import React, { useEffect, useState } from "react"
import { jsx } from "theme-ui"
import { FiCheckSquare, FiEye } from "react-icons/fi"
import slug from "../../lib/slug"
import countapi from "countapi-js"
import { useStaticQuery, graphql } from "gatsby"
const ViewCount = ({ hostname, id }) => {
  const [views, setViews] = useState(0)
  const { sanityUserInterface } = useStaticQuery(graphql`
    query HideViews {
      sanityUserInterface {
        cartOptions {
          hideViews
        }
      }
    }
  `)
  const hideViews =
    (sanityUserInterface &&
      sanityUserInterface.cartOptions &&
      sanityUserInterface.cartOptions.hideViews) ||
    false

  useEffect(() => {
    if (hostname) {
      countapi
        .get(slug(hostname), slug(id))
        .then(result => {
          setViews(result.value)
        })
        .catch(e => console.log(e))
    }
  }, [])
  return (
    views &&
    !hideViews && (
      <span
        sx={{
          fontFamily: "monospace",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginLeft: "auto",
        }}
      >
        {views} <FiEye sx={{ marginLeft: 1, color: "text" }} />
      </span>
    )
  )
}

export default ViewCount
