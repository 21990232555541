import Money from "./Money.jsx"
const messageWhatsapp = ({
  cart,
  userData,
  money,
  messageExtra,
  linkCartShare,
  productOnly,
  cupon,
}) => {
  const products = cart
  let listProductsText = ""
  let totalPrice = 0
  // const line = "........%0A"
  const line = "---------%0A"
  const lineTop = "═══════════════════╗%0A"
  const lineBottom = "═══════════════════╝%0A"
  let addressText = ""

  if (cart && products.length !== 0) {
    products.forEach(product => {
      if (
        product &&
        product.order &&
        product.order.length > 0 &&
        product.order[0].count
      ) {
        const _price = product.order[0].price
          ? `${Money(product.order[0].price)}`
          : "precio a consultar"
        listProductsText += lineTop
        listProductsText += `✅ ${product.title} ${product.sku || ""} ${
          !(product.variants && product.variants.name)
            ? ` (${_price}) _📦 ${
                product.order[0].count > 1
                  ? `unidades: ${product.order[0].count}`
                  : `unidad: ${product.order[0].count}`
              }_💵 SubTotal = ${
                product.order[0].price
                  ? `${Money(product.order[0].count * product.order[0].price)}`
                  : `${_price}`
              }`
            : ""
        }_`

        if (
          product.variants &&
          product.variants.name &&
          product.order.length > 0
        ) {
          product.order.forEach(variant => {
            const _price = variant.price
              ? `${Money(variant.price)}`
              : "precio a consultar"
            totalPrice += variant.price * variant.count
            listProductsText += `_${variant.name}: ${
              variant.tag
            } (${_price})_📦 ${
              variant.count > 1
                ? `unidades: ${variant.count}`
                : `unidad: ${variant.count}`
            }_💵 SubTotal = ${
              variant.price
                ? `${Money(variant.price * variant.count)}`
                : `${_price}`
            }_`
          })
        } else {
          totalPrice += product.order[0].count * product.order[0].price
        }
        if (product.formData && product.formData.length > 0) {
          listProductsText += `_`
          product.formData.forEach(ask => {
            if (ask.name && ask.value) {
              listProductsText += `${ask.name}: ${ask.value}_`
            }
          })
        }
        // listProductsText += lineBottom
      }
    })
  }
  let listUserData = ""
  Object.keys(userData).forEach((key, i) => {
    if (Object.values(userData)[i])
      if (key !== "name") {
        listUserData += `${key}: ${Object.values(userData)[i]}_`
      }
  })
  let message = `👋 Hola soy ${
    userData.name
  } _Deseo estos productos: _${listProductsText}_${line}${
    addressText && `_${addressText}_${line}`
  }💰 Total a pagar: ${
    cupon
      ? cupon._type == "cuponMount"
        ? `${Money(totalPrice)} - ${Money(cupon.mount)}(${
            cupon.code
          }) = ${Money(totalPrice - cupon.mount)}`
        : `${Money(totalPrice)} - ${cupon.percent}% (${cupon.code}) = ${Money(
            totalPrice - (cupon.percent / 100) * totalPrice
          )}`
      : Money(totalPrice.toFixed(2))
  } _${messageExtra ? `~${messageExtra}~` : ""}${
    listUserData ? `_${listUserData}_` : ""
  }${linkCartShare ? `_Link del carrito: ${linkCartShare}` : ""}
    `

  if (typeof productOnly !== "object") {
    return message
  } else {
    message = `👋 Hola soy ${
      userData.name
    } _Deseo información sobre este producto: __*${productOnly.title}*${
      productOnly.variants
        ? productOnly.variants.name
          ? `_${productOnly.variants.name}: ${productOnly.variants.tag || ""}`
          : `_variante: ${productOnly.variants.tag || ""}`
        : ""
    }__${
      typeof window !== "undefined" &&
      `${window.location.origin}/${productOnly.slug}`
    }`
    return message
  }
}
export default messageWhatsapp
