import React from "react"
import search from "./search"
import { useStaticQuery, graphql } from "gatsby"
const SearchProductById = id => {
  const { allSanityProduct } = useStaticQuery(graphql`
    query allSearchProductById {
      allSanityProduct {
        nodes {
          id
          title
          slug
          description
          sku
          price
          img {
            asset {
              metadata {
                dimensions {
                  height
                  width
                }
              }
              url
              fluid(maxWidth: 800) {
                base64
                aspectRatio
                src
                srcSet
                srcWebp
                srcSetWebp
                sizes
              }
              fixed(height: 50, width: 50) {
                base64

                width
                height
                src
                srcSet
                srcWebp
                srcSetWebp
              }
            }
            hotspot {
              height
              width
              x
              y
            }
          }

          categories {
            id
            title
            slug
            icon {
              asset {
                fluid {
                  base64
                  aspectRatio
                  src
                  srcSet
                  srcWebp
                  srcSetWebp
                  sizes
                }
                fixed(height: 25, width: 25) {
                  base64

                  width
                  height
                  src
                  srcSet
                  srcWebp
                  srcSetWebp
                }
              }
            }
          }

          quantityOptions {
            active
            hiddenAddQuantity
            quantityStep
          }
          discount {
            active

            priceNotReduced
          }
          multimediaOptions {
            active
            fyuse
            youtube
            listImages {
              asset {
                fluid(maxWidth: 800) {
                  base64
                  aspectRatio
                  src
                  srcSet
                  srcWebp
                  srcSetWebp
                  sizes
                }
                fixed(height: 50, width: 50) {
                  base64

                  width
                  height
                  src
                  srcSet
                  srcWebp
                  srcSetWebp
                }
              }
            }
          }

          variants {
            name
            tag

            list {
              img {
                asset {
                  metadata {
                    dimensions {
                      height
                      width
                    }
                  }
                  url
                  fluid(maxWidth: 800) {
                    base64
                    aspectRatio
                    src
                    srcSet
                    srcWebp
                    srcSetWebp
                    sizes
                  }
                  fixed(height: 50, width: 50) {
                    base64

                    width
                    height
                    src
                    srcSet
                    srcWebp
                    srcSetWebp
                  }
                }
                hotspot {
                  height
                  width
                  x
                  y
                }
              }

              quantityOptions {
                active
                hiddenAddQuantity
                quantityStep
              }

              multimediaOptions {
                active
                fyuse
                youtube
                listImages {
                  asset {
                    fluid {
                      base64
                      aspectRatio
                      src
                      srcSet
                      srcWebp
                      srcSetWebp
                      sizes
                    }
                  }
                }
              }
              otherOptionsProduct {
                hiddenData
              }
              description
              price
              tag
              title
              discount {
                active
                priceNotReduced
              }
            }
          }
          otherOptionsProduct {
            hiddenData
          }
        }
      }
    }
  `)
  return search(allSanityProduct.nodes, "id", id)
}

export default SearchProductById
