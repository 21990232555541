// ** @jsx jsx *
import React, { useEffect } from "react"
import Img from "gatsby-image"
import { jsx, Styled, Flex } from "theme-ui"
const SnackBar = ({ fixedImg, text, Icon, active, index, refParent }) => {
  useEffect(() => {
    if (active && refParent && refParent.current) {
      const scroll = refParent.current.parentElement
      const childs = refParent.current.childElementCount || 1
      const widthElement = refParent.current.clientWidth
      const steps = widthElement / childs
      refParent.current.parentElement.scroll(steps * (index + 1), 0)
    }
  }, [])
  return (
    <Flex
      className="pointer press"
      sx={{
        border: active ? "simple" : "opacity",
        borderRadius: 1,
        paddingLeft: 3,
        paddingRight: 3,
        alignItems: "center",
        marginLeft: 1,
        marginRight: 1,
      }}
    >
      <div
        sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
      >
        {Icon && <Icon sx={{ width: 25, height: 25, marginRight: 2 }} />}
        {fixedImg && (
          <Img
            fixed={fixedImg}
            alt={text}
            sx={{ width: 25, height: 25, marginRight: 2 }}
          />
        )}
      </div>
      <div>
        <Styled.h3>{text}</Styled.h3>
      </div>
    </Flex>
  )
}

export default SnackBar
