// ** @jsx jsx *
import React, { useState, useEffect, useContext } from "react"
import Img from "gatsby-image"
import { IoIosArrowDropleft, IoIosArrowDropright } from "react-icons/io"
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch"
import { jsx, Styled, Button, Text } from "theme-ui"
import { ImYoutube } from "react-icons/im"
import { GiSemiClosedEye } from "react-icons/gi"
const ImagesProduct = ({ product, images, big, multimediaOptions }) => {
  const [selectImg, setSelectImg] = useState(0)
  const heightScale = [
    images.length > 1 ? "30vh" : big ? "50vh" : "40vh",
    320,
    380,
  ]
  const nextImage = () => {
    setSelectImg(
      selectImg + 1 < images.length - 1 ? selectImg + 1 : images.length - 1
    )
  }
  const previousImage = () => {
    setSelectImg(selectImg - 1 > 0 ? selectImg - 1 : 0)
  }
  let youtube = false
  let fyuse = false
  try {
    youtube =
      multimediaOptions &&
      multimediaOptions.active &&
      multimediaOptions.youtube &&
      multimediaOptions.youtube.indexOf("youtu.be") != -1
        ? multimediaOptions.youtube.split("youtu.be/")[1]
        : multimediaOptions.youtube.indexOf("watch?v=") != -1
        ? multimediaOptions.youtube.split("watch?v=")[1]
        : multimediaOptions.youtube.indexOf("embed/") != -1
        ? multimediaOptions.youtube.split("embed/")[1]
        : false
  } catch (error) {
    // console.log(`multimediaOptions:`)
    // console.log(multimediaOptions)
    // console.log(`Youtube: ${youtube}`)
    // console.error(error)
  }
  try {
    fyuse =
      multimediaOptions &&
      multimediaOptions.active &&
      multimediaOptions.fyuse &&
      multimediaOptions.fyuse.indexOf("embed/") != -1
        ? multimediaOptions.fyuse.split("embed/")[1]
        : multimediaOptions.fyuse.indexOf("v/") != -1
        ? multimediaOptions.fyuse.split("v/")[1]
        : false
  } catch (error) {
    // console.log(`multimediaOptions:`)
    // console.log(multimediaOptions)
    // console.log(`Fyuse: ${fyuse}`)
    // console.error(error)
  }

  const activateSelectImages =
    images.length + (youtube ? 1 : 0) + (fyuse ? 1 : 0) > 1
  return (
    <div
      sx={{
        position: "relative",
        display: "flex",
        justifyContent: ["center", "flex-start"],
        flexDirection: ["center", "flex-start"],
        alignItems: "center",
        marginTop: "25px",
        width: ["width1", "halfWidth2", "halfWidth3"],
        borderRadius: 2,
      }}
    >
      {images.map((img, i) => {
        return (
          selectImg == i && (
            <div
              key={`images-${i}`}
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: ["center"],
                alignItems: "center",
                boxShadow: "pressed",
                position: "relative",
                // border: "simpleOpacity",
                // backgroundColor: "backgroundLighten",
                borderRadius: 2,
                // width: ["width1", "width2", "width3"],
                paddingBottom: activateSelectImages ? ["62px"] : "0",
                minHeight: heightScale,
              }}
            >
              {img && img.asset && img.asset.fluid && (
                <TransformWrapper>
                  <TransformComponent>
                    <div
                      sx={{
                        position: "relative",
                        width: ["width1", "halfWidth2", "halfWidth3"],
                        // backgroundColor: "text",
                        height: heightScale,
                        borderRadius: 2,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        // backgroundColor: "soft",
                      }}
                    >
                      <Img
                        className="enter"
                        sx={{ width: "100%", maxHeight: heightScale }}
                        fluid={img.asset.fluid}
                        fadeIn
                        alt={product.title}
                        imgStyle={{
                          height: "95%",
                          borderRadius: 16,
                          width: "auto",
                          left: "50%",
                          top: "50%",
                          transform: "translate(-50%, -50%)",
                        }}
                      />
                    </div>
                  </TransformComponent>
                </TransformWrapper>
              )}
              <div
                sx={{
                  "& .arrow": {
                    position: "absolute",
                    fontSize: 4,
                    top: "50%",
                    transform: "translate(0,-50%)",
                    backgroundColor: "primary",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: 35,
                    height: 35,
                    backgroundColor: "background",
                  },
                }}
              >
                {selectImg > 0 && (
                  <div
                    className="arrow pointer"
                    sx={{
                      left: 0,
                      // boxShadow: "2px 0 5px 5px rgba(0,0,0,0.4) !important",
                      boxShadow: "flatDark",
                      borderRadius: "0 50% 50% 0",
                    }}
                    onClick={previousImage}
                  >
                    <IoIosArrowDropleft />
                  </div>
                )}
                {selectImg < images.length - 1 && (
                  <div
                    className="arrow pointer"
                    sx={{
                      right: 0,
                      boxShadow: "flatLight",
                      borderRadius: "50% 0 0 50%",
                    }}
                    onClick={nextImage}
                  >
                    <IoIosArrowDropright />
                  </div>
                )}
              </div>
            </div>
          )
        )
      })}
      {selectImg == "youtube" && youtube && (
        <div
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: ["center"],
            alignItems: "center",
            // boxShadow: "pressed",
            position: "relative",
            // border: "simpleOpacity",
            // backgroundColor: "backgroundLighten",
            borderRadius: 2,
            // width: ["width1", "width2", "width3"],
            paddingBottom: activateSelectImages ? ["62px"] : "0",
            width: ["width1", "halfWidth2", "halfWidth3"],
            // minHeight: heightScale,
          }}
        >
          <div
            sx={{
              position: "relative",
              width: "100%",
              paddingBottom: "56.25%",
              marginBottom: 4,
            }}
          >
            <iframe
              sx={{
                position: "absolute",
                top: 0,
                bottom: 0,
                left: 0,
                right: 0,
                boxShadow: "flat",
                width: "100%",
                height: "100%",
                borderRadius: 2,
                border: 0,
              }}
              frameBorder="0"
              autoPlay={true}
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              src={`https://www.youtube.com/embed/${youtube}`}
              allowFullScreen
            ></iframe>
          </div>
        </div>
      )}
      {selectImg == "fyuse" && fyuse && (
        <div
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: ["center"],
            alignItems: "center",
            // boxShadow: "pressed",
            position: "relative",
            // border: "simpleOpacity",
            // backgroundColor: "backgroundLighten",
            borderRadius: 2,
            // width: ["width1", "width2", "width3"],
            paddingBottom: activateSelectImages ? ["62px"] : "0",
            width: ["width1", "halfWidth2", "halfWidth3"],
          }}
        >
          <div
            sx={{
              width: "100%",
              marginBottom: 4,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <iframe
              sx={{
                boxShadow: "flat",
                borderRadius: 2,
                border: 0,
                width: ["width1", "halfWidth2", "halfWidth3"],
                height: ["50vh"],
              }}
              src={`https://fyu.se/v/embed/${fyuse}?preload=1&nologo=1&gyroscope=1&autoPlay=1&accelerometer=1`}
              allow="accelerometer; autoPlay; gyroscope"
              allowFullScreen
            ></iframe>
          </div>
        </div>
      )}
      <div
        sx={{
          display: "flex",
          position: "absolute",
          width: "100%",
          justifyContent: "flex-start",

          bottom: 0,
        }}
      >
        <div
          sx={{
            display: "flex",
            maxWidth: ["100%"],
            overflowX: "auto",
          }}
        >
          {images.map((img, i) => {
            return (
              activateSelectImages && (
                <div
                  key={`images-miniature-${i}`}
                  className="pointer"
                  onClick={() => setSelectImg(i)}
                >
                  {img && img.asset && img.asset.fixed && (
                    <Img
                      sx={{
                        width: 50,
                        height: 50,
                        borderRadius: 1,
                        margin: 1,
                        border: selectImg == i ? "simple" : "opacity",
                      }}
                      fixed={img.asset.fixed}
                    />
                  )}
                </div>
              )
            )
          })}
        </div>
        {multimediaOptions && multimediaOptions.active && (
          <>
            {multimediaOptions.youtube && (
              <button
                onClick={() => setSelectImg("youtube")}
                className="pointer"
                sx={{
                  display: "flex",
                  width: "max",
                  justifyContent: "center",
                  alignItems: "center",
                  height: 54,
                  margin: 1,
                  paddingLeft: 2,
                  paddingRight: 2,
                  borderRadius: 1,
                  border: selectImg == "youtube" ? "simple" : "opacity",
                  backgroundColor: "background",
                  color: "text",
                }}
              >
                <ImYoutube sx={{ fill: "red", fontSize: 3, marginRight: 1 }} />{" "}
                Video
              </button>
            )}
            {multimediaOptions.fyuse && (
              <button
                onClick={() => setSelectImg("fyuse")}
                className="pointer"
                sx={{
                  display: "flex",
                  height: 54,
                  justifyContent: "center",
                  alignItems: "center",
                  margin: 1,
                  paddingLeft: 2,
                  paddingRight: 2,
                  borderRadius: 1,
                  border: selectImg == "fyuse" ? "simple" : "opacity",
                  backgroundColor: "background",
                  color: "text",
                }}
              >
                <GiSemiClosedEye
                  sx={{ fill: "primary", fontSize: 3, marginRight: 1 }}
                />
                3D!
              </button>
            )}
          </>
        )}
      </div>
    </div>
  )
}
export default ImagesProduct
