import React from "react"
import { useStaticQuery, graphql } from "gatsby"
const Money = price => {
  const { sanitySetting } = useStaticQuery(graphql`
    query Money {
      sanitySetting {
        money
      }
    }
  `)
  let money = (sanitySetting && sanitySetting.money) || "$"
  let priceMoney = ""
  priceMoney = price
    ? money.indexOf("<") === -1
      ? `${price}${money}`
      : `${money.replace("<", "")}${price}`
    : ""

  return priceMoney
}

export default Money
