// ** @jsx jsx *
import React, { useState, useEffect } from "react"
import { jsx, useThemeUI } from "theme-ui"
import Menu from "./ui/Menu"

import { useStaticQuery, graphql } from "gatsby"
import Seo from "./Seo"
import theme from "../gatsby-plugin-theme-ui"
import OneSignal from "react-onesignal"
const Layout = ({ children, seo }) => {
  const [hiddenMenu, setHiddenMenu] = useState(false)
  const {
    sanitySetting,
    sanityUserInterface,
    sanityCompanyInfo,
  } = useStaticQuery(graphql`
    query dataLayout {
      sanitySetting {
        description
        logo {
          asset {
            url
            fixed(width: 45, height: 45) {
              base64

              width
              height
              src
              srcSet
              srcWebp
              srcSetWebp
            }
            fluid(maxWidth: 150) {
              base64
              srcWebp
              srcSetWebp
              srcSet
              src
              sizes
              aspectRatio
            }
          }
        }
        cover {
          asset {
            url
          }
        }
        title
        whatsapp {
          message
          phone
        }

        money
      }
      sanityCompanyInfo {
        socialMedia {
          facebook
          instagram
          tiktok
          youtube
        }
      }
    }
  `)
  const useTheme = useThemeUI()
  let colors
  try {
    colors =
      (useTheme && useTheme.theme && useTheme.theme.colors) || theme("none")
  } catch (error) {}

  let SEO = {
    title: sanitySetting.title,
    description: sanitySetting.description,
    image: sanitySetting.cover.asset.url,
    logo: sanitySetting.logo.asset.url,
    color: colors.primary,
  }
  if (seo) {
    SEO = { ...SEO, ...seo }
  }

  useEffect(() => {
    if (typeof window !== "undefined") {
      const url = new URL(window.location.href)
      url.searchParams.get("hiddenMenu") !== null && setHiddenMenu(true)
      // OneSignal.initialize("fd16c71e-2886-44dd-a5b4-f3b9972adc11", {
      //   autoRegister: true,
      //   notifyButton: {
      //     enable: true,
      //   },
      // })
    }
  }, [])
  return (
    <>
      <div>
        <Seo {...SEO} />
        {!hiddenMenu && (
          <Menu
            sanitySetting={sanitySetting}
            sanityCompanyInfo={sanityCompanyInfo}
          />
        )}
        {children}
      </div>
      <style jsx="true" global="true">
        {`
          body::-webkit-scrollbar {
            width: 0;
            height: 0;
            cursor: pointer;
          }

          @media (min-width: 500px) {
            ::-webkit-scrollbar {
              width: 10px;
              height: 10px;
              cursor: pointer;
            }
            ::-webkit-scrollbar-thumb {
              background: linear-gradient(
                13deg,
                ${colors.text} 14%,
                ${colors.text} 64%
              );
              cursor: pointer;
              border-radius: 10px;
              transition: 0.2s;
            }
            ::-webkit-scrollbar-thumb:hover {
              opacity: 0.9;
            }
            ::-webkit-scrollbar-track {
              cursor: pointer;
              background: ${colors.blur};
              border-radius: 7px;
              box-shadow: inset 7px 10px 12px ${colors.blur};
              cursor: pointer;
            }
          }
        `}
      </style>
    </>
  )
}

export default Layout
