const fade = (hex, opacity) => {
  var c
  if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
    c = hex.substring(1).split("")
    if (c.length == 3) {
      c = [c[0], c[0], c[1], c[1], c[2], c[2]]
    }
    c = "0x" + c.join("")
    return `rgba(${[(c >> 16) & 255, (c >> 8) & 255, c & 255].join(",")},${
      opacity || 1
    })`
  }
  throw new Error("Bad Hex")
}
function LightenDarkenColor(col, amt) {
  // console.log(col, amt)
  var usePound = false

  if (col[0] == "#") {
    col = col.slice(1)
    usePound = true
  }

  var num = parseInt(col, 16)

  var r = (num >> 16) + amt

  if (r > 255) r = 255
  else if (r < 0) r = 0

  var b = ((num >> 8) & 0x00ff) + amt

  if (b > 255) b = 255
  else if (b < 0) b = 0

  var g = (num & 0x0000ff) + amt

  if (g > 255) g = 255
  else if (g < 0) g = 0

  return (usePound ? "#" : "") + (g | (b << 8) | (r << 16)).toString(16)
}
const black = (opacity = 1) => `rgba(0,0,0,${opacity})`
const white = (opacity = 1) => `rgba(255,255,255,${opacity})`

let whatsappColors = {
  whatsappBackground: "#25d366",
  whatsappBackgroundSecondary: "#128c7e",
  whatsappText: "#ece5dd",
}

let colorsDark = {
  theme: "dark",
  colorPrimary: "#80fff7",
  colorSecondary: "#c91d6a",
  background: "#2a2f36",
}
let colorsLight = {
  theme: "light",
  colorPrimary: "#128c7e",
  colorSecondary: "#bb243a",
  background: "#ededed",
}
let colorsDefault = colorsDark
let colors = colorsDefault

export default {
  fonts: {
    body: '"Montserrat"',
    heading: '"Roboto"',
    monospace: "Menlo, monospace",
  },

  colors: {
    text: colors.theme == "light" ? black() : white(),
    textSecondary: white(),
    textBlur: colors.theme == "light" ? black(0.7) : white(0.9),
    background: colors.background,
    backgroundOpacity: "rgba(0, 0, 0, 0.9)",
    backgroundDarken: LightenDarkenColor(colors.background, -10),
    backgroundLighten: LightenDarkenColor(colors.background, 10),
    backgroundSecondary:
      colors.theme == "light"
        ? white()
        : LightenDarkenColor(colors.background, -10),
    soft: colors.theme == "light" ? white(0.3) : white(0.05),
    primary: colors.colorPrimary,
    secondary: colors.colorSecondary,
    blur: colors.theme == "light" ? white(1) : black(0.3),
    whatsappBackground: whatsappColors.whatsappBackground,
    whatsappBackgroundSecondary: whatsappColors.whatsappBackgroundSecondary,
    whatsappText: whatsappColors.whatsappText,
  },
  radii: [4, 8, 16],
  borders: {
    simple: `2px solid ${colors.theme == "light" ? black() : white()}`,
    backgroundSimple: `2px solid ${colors.background}`,
    opacity: `2px solid ${colors.theme == "light" ? black(0.1) : white(0.1)}`,
    primarySimple: `2px solid ${colors.colorPrimary}`,
  },
  shadows: {
    shadowInset: `inset 0px -2px 12px 1px ${LightenDarkenColor(
      colors.background,
      -15
    )}`,

    pressed: `inset 9px 9px 15px ${LightenDarkenColor(
      colors.background,
      -15
    )}, inset -9px -9px 15px ${LightenDarkenColor(colors.background, 15)}`,
    flat: `9px 9px 15px ${LightenDarkenColor(
      colors.background,
      -20
    )}, -9px -9px 15px ${LightenDarkenColor(colors.background, 20)}`,
    pressedPrimary: `inset 9px 9px 15px ${LightenDarkenColor(
      colors.colorPrimary,
      -15
    )}, inset -9px -9px 15px ${LightenDarkenColor(colors.colorPrimary, 15)}`,
    flatPrimary: `4.5px 4.5px 12px ${LightenDarkenColor(
      colors.colorPrimary,
      -160
    )}, -4.5px -4.5px 12px ${LightenDarkenColor(colors.colorPrimary, 160)}`,

    pressedWhatsapp: `inset 9px 9px 15px ${LightenDarkenColor(
      whatsappColors.whatsappBackgroundSecondary,
      -15
    )}, inset -9px -9px 15px ${LightenDarkenColor(
      whatsappColors.whatsappBackgroundSecondary,
      15
    )}`,
    flatWhatsapp: `9px 9px 15px ${LightenDarkenColor(
      whatsappColors.whatsappBackgroundSecondary,
      -20
    )}, -9px -9px 15px ${LightenDarkenColor(
      whatsappColors.whatsappBackgroundSecondary,
      20
    )}`,

    flatLight: `-4px -4px 7px ${LightenDarkenColor(colors.background, 20)}`,
    flatDark: `4px 4px 4px ${LightenDarkenColor(colors.background, -20)}`,
  },
  sizes: {
    width1: "95vw",
    width2: "500px",
    width3: "66.6vw",
    halfWidth1: "42.5vw",
    halfWidth2: "250px",
    halfWidth3: "33.3vw",
  },
  functions: {},
  styles: {
    div: {},
    h1: {
      fontFamily: "body",
      color: "primary",
      fontSize: 4,
    },
    h2: {
      fontFamily: "heading",
      color: "primary",
      fontSize: 2,
      margin: 0,
    },
    h3: {
      fontFamily: "body",
      color: "textBlur",
      fontSize: 0,
    },
    h4: {
      fontFamily: "body",
      color: "textBlur",
      fontSize: 0,
    },
    h5: {
      fontFamily: "body",
      color: "textBlur",
      fontSize: 0,
      padding: 0,
      margin: 0,
    },
    h6: {
      fontFamily: "body",
      color: "textBlur",
      fontWeight: "initial",
      fontSize: 0,
      margin: 0,
    },
    p: {
      fontFamily: "body",
      color: "textBlur",
      fontSize: 0,
    },
  },
  text: {
    primaryInvert: {
      fontFamily: "body",
      color: "background",
    },
    primary: {
      fontFamily: "body",
      color: "text",
    },
    caption: {
      fontFamily: "body",
      color: "text",
      fontSize: 0,
      fontWeight: "bold",
      textTransform: "uppercase",
    },
    body: {
      fontFamily: "body",
      fontWeight: "normal",
      color: "text",
      fontSize: 0,
    },
    tag: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      fontFamily: "body",
      backgroundColor: "background",
      border: "opacity",
      borderRadius: 2,
      padding: 2,
      paddingTop: 1,
      paddingBottom: 1,
      fontSize: 1,
      fontWeight: "bold",
      color: "text",
    },
  },
  buttons: {
    default: {
      cursor: "pointer",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      padding: 2,
      fontFamily: "body",
      borderRadius: 1,
    },
    primary: {
      variant: "buttons.default",
      backgroundColor: "primary",
      color: "background",
    },
    secondary: {
      variant: "buttons.default",
      backgroundColor: "secondary",
      color: "textSecondary",
    },
    outlined: {
      variant: "buttons.default",
      border: "opacity",
      backgroundColor: "background",
      color: "text",
    },
    primaryFlat: {
      variant: "buttons.default",
      backgroundColor: "background",
      boxShadow: "flat",
      color: "primary",
      "&:active": {
        boxShadow: "pressed",
      },
    },
  },
  badges: {
    primary: {
      color: "textSecondary",
      backgroundColor: "secondary",
      fontFamily: "monospace",
    },
    secondary: { color: "textSecondary", backgroundColor: "secondary" },
    invert: { color: "background", backgroundColor: "text" },
  },

  layout: {
    flat: {
      backgroundColor: "background",
      boxShadow: "flat",
      borderRadius: 2,
    },
  },
  div: {
    flat: {
      backgroundColor: "background",
      boxShadow: "flat",
      borderRadius: 1,
    },
    flex: {
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "center",
    },
    flexWrap: {
      display: "flex",
      flexWrap: "wrap",
      justifyContent: "flex-start",
      alignItems: "center",
    },
    flexColumn: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "flex-start",
      alignItems: "center",
    },
  },
  messages: {
    primary: {
      variant: "text.primaryInvert",
      // backgroundColor: "primary",
      color: "background",
    },
  },
}
