// ** @jsx jsx *
import React from "react"
import { jsx, Text, IconButton } from "theme-ui"
import Img from "gatsby-image"
import {
  AiOutlineFacebook,
  AiOutlineInstagram,
  AiOutlineYoutube,
  AiOutlineWhatsApp,
} from "react-icons/ai"
import { SiTiktok } from "react-icons/si"
import CategoriesSnacks from "./CategoriesSnacks"
import WhatsappApi from "../lib/whatsappApi"
const BusinessInfo = ({ sanitySetting, sanityCompanyInfo }) => {
  console.log(sanitySetting)
  return (
    <div>
      <div sx={{ variant: "div.flexColumn" }}>
        <div sx={{ position: "relative" }}>
          <div sx={{ width: 100, borderRadius: 2 }}>
            {sanitySetting.logo &&
              sanitySetting.logo.asset &&
              sanitySetting.logo.asset.fluid && (
                <Img
                  sx={{ borderRadius: 2 }}
                  fluid={sanitySetting.logo.asset.fluid}
                  alt={`Logo ${sanitySetting.title} ${sanitySetting.description}`}
                />
              )}
          </div>
          <div>
            <a
              sx={{
                variant: "buttons.whatsapp",
                borderRadius: "50%",
                position: "absolute",
                bottom: -15,
                right: -15,
              }}
              target="_blank"
              href={WhatsappApi(
                sanitySetting.whatsapp.phone,
                sanitySetting.whatsapp.message
              )}
            >
              <AiOutlineWhatsApp
                sx={{ fontSize: "1.7em", fontWeight: "bold" }}
              />
            </a>
          </div>
        </div>

        <br />
        <div>
          <Text variant="primary">{sanitySetting.title}</Text>
        </div>
        <br />
        <div sx={{ marginBottom: 2 }}>
          <Text variant="body">{sanitySetting.description}</Text>
        </div>
        <br />
        <div
          sx={{
            maxHeight: "35vh",
            width: "-webkit-fill-available",
            padding: 3,
            borderRadius: 2,
            overflowY: "auto",
            variant: "div.flexWrap",
            backgroundColor: "background",
          }}
        >
          <CategoriesSnacks />
        </div>
        <br />
        {sanityCompanyInfo && sanityCompanyInfo.socialMedia && (
          <div sx={{ width: "100%" }}>
            <div sx={{ variant: "div.flex", marginTop: 2 }}>
              {sanityCompanyInfo.socialMedia.facebook && (
                <IconButton
                  as={"a"}
                  target="_blank"
                  href={sanityCompanyInfo.socialMedia.facebook}
                >
                  <AiOutlineFacebook sx={{ fontSize: 4 }} />
                </IconButton>
              )}
              {sanityCompanyInfo.socialMedia.instagram && (
                <IconButton
                  as={"a"}
                  target="_blank"
                  href={sanityCompanyInfo.socialMedia.instagram}
                >
                  <AiOutlineInstagram sx={{ fontSize: 4 }} />
                </IconButton>
              )}
              {sanityCompanyInfo.socialMedia.youtube && (
                <IconButton as={"a"} target="_blank">
                  <AiOutlineYoutube
                    sx={{ fontSize: 4 }}
                    href={sanityCompanyInfo.socialMedia.youtube}
                  />
                </IconButton>
              )}
              {sanityCompanyInfo.socialMedia.tiktok && (
                <IconButton
                  as={"a"}
                  target="_blank"
                  href={sanityCompanyInfo.socialMedia.tiktok}
                >
                  <SiTiktok sx={{ fontSize: 3 }} />
                </IconButton>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default BusinessInfo
