// ** @jsx jsx *
import React, { useState, useEffect } from "react"
import { jsx, Styled, Button, Text } from "theme-ui"
import { IoIosAddCircleOutline, IoIosRemoveCircleOutline } from "react-icons/io"
import { FiShoppingCart } from "react-icons/fi"
import { FaRegTrashAlt } from "react-icons/fa"
import { navigate } from "gatsby"
import search from "../../lib/search"
import { SiWhatsapp } from "react-icons/si"
import Modal from "../ui/Modal.jsx"
import Form from "../Form.jsx"
import Product from "../Product"
import ReactPixel from "../../lib/react-facebook-pixel"
import { useStaticQuery, graphql } from "gatsby"
import Money from "../../lib/Money.jsx"
const Controls = ({
  select,
  product,
  variants,
  setVariants,
  cart,
  setCart,
  formData,
  money,
  activeCart,
  onComplete,
  insideModal,
  overwriteSend,
  overwriteRemove,
}) => {
  const { sanityIntegration } = useStaticQuery(graphql`
    query ControlsPixelFacebook {
      sanityIntegration {
        facebookPixel {
          pixelId
          currency
        }
      }
    }
  `)
  const [openForm, setOpenForm] = useState(false)
  let totalCount = 0
  variants.forEach(({ count, hiddenExtra }) => {
    if (!hiddenExtra) {
      totalCount += count
    }
  })
  let totalPrice = 0
  variants.forEach(({ count, price }) => (totalPrice += count * price))

  let activeQuantityOptions =
    variants[select].quantityOptions &&
    variants[select].quantityOptions.active &&
    variants[select].quantityOptions.quantityStep > 0

  let hiddenAddQuantity =
    variants[select].quantityOptions &&
    variants[select].quantityOptions.hiddenAddQuantity
  const addProduct = q => {
    let quantity = typeof q == "number" ? q : 1
    let newVariants = [...variants]
    newVariants[select].count = newVariants[select].count + quantity
    setVariants(newVariants)
  }
  const removeProduct = q => {
    let quantity = typeof q == "number" ? q : 1
    let newVariants = [...variants]
    newVariants[select].count =
      newVariants[select].count - quantity >= 0
        ? newVariants[select].count - quantity
        : 0
    setVariants(newVariants)
  }
  let priceMoney = Money(totalPrice)
  const addCart = () => {
    if (typeof overwriteSend == "function") {
      overwriteSend(totalCount)
    } else {
      const productOrder = {
        ...product,
        totalPrice,
        totalCount,
        totalPriceWithCurrency: priceMoney,
        order: variants.filter(variant => variant.count > 0),
        formData: formData || [],
      }
      let newCart = [...cart]

      const matchCart = search(newCart, "id", product.id)
      if (matchCart) {
        newCart[matchCart.index] = productOrder
      } else {
        newCart.push(productOrder)
      }

      setCart(newCart)
      typeof onComplete == "function" ? onComplete() : navigate("/")

      if (typeof window !== "undefined") {
        if (
          sanityIntegration &&
          sanityIntegration.facebookPixel &&
          sanityIntegration.facebookPixel &&
          sanityIntegration.facebookPixel.pixelId
        ) {
          ReactPixel.track("AddToCart", {
            whatshop_content_ids: product.id,
            whatshop_content_name: product.title,
            whatshop_product: product.title,
            whatshop_product_id: product.id,
            value: totalPrice,
            quantity: totalCount,
            currency: sanityIntegration.facebookPixel.currency,
          })
        }
      }
    }
  }
  const removeCart = () => {
    if (typeof overwriteRemove == "function") {
      overwriteRemove(totalCount)
    } else {
      let newVariants = [...variants]
      newVariants = newVariants.map(variant => ({ ...variant, count: 0 }))
      setVariants(newVariants)

      let newCart = [...cart]
      const matchCart = search(newCart, "id", product.id)
      if (matchCart) {
        newCart.splice(matchCart.index, 1)
      }
      setCart(newCart)
      typeof onComplete == "function" ? onComplete() : navigate("/")
    }
  }
  // console.log(product.title)
  // console.log(insideModal)

  return (
    <div
      sx={{
        // position: "abosulte",

        position: insideModal ? "static" : "fixed",
        bottom: 0,
        left: 0,
        right: 0,
        zIndex: 2,
        width: "100%",
        maxWidth: insideModal ? "85vw" : "",
        height: 60,
        boxShadow: insideModal ? "flatSmall" : "",
        borderRadius: insideModal ? 2 : "",
        backgroundColor: "backgroundSecondary",
        paddingLeft: insideModal ? 2 : "",
        paddingRight: insideModal ? 2 : "",
        // maxWidth: [ "width1", "width2", "width3"],
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {activeCart ? (
        <div
          sx={{
            position: insideModal ? "relative" : "static",

            width: "100%",
            height: 60,
            // backgroundColor: "backgroundSecondary",
            maxWidth: ["width1", "width2", "width3"],
          }}
        >
          <div
            sx={{
              // width: "100%",

              height: "100%",

              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div
              sx={{
                width: "100%",
                display: "flex",
                padding: 2,
                justifyContent: "flex-start",
                alignItems: "center",
              }}
            >
              <div onClick={removeCart}>
                <FaRegTrashAlt sx={{ fontSize: 2 }} className="pointer" />
              </div>
            </div>

            <div
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
              }}
            >
              {activeQuantityOptions && (
                <div
                  onClick={() =>
                    removeProduct(variants[select].quantityOptions.quantityStep)
                  }
                >
                  <div
                    className="pointer"
                    sx={{
                      variant: "div.flex",
                      width: 30,
                      height: 30,
                      color: "secondary",
                      border: "secondary",
                      borderRadius: "50%",
                      fontFamily: "monospace",
                      fontWeight: "bold",
                    }}
                  >
                    -{variants[select].quantityOptions.quantityStep}
                  </div>
                </div>
              )}
              {!hiddenAddQuantity && (
                <div onClick={removeProduct} sx={{ marginLeft: 2 }}>
                  <IoIosRemoveCircleOutline
                    sx={{
                      fontSize: 5,
                      fill: "secondary",
                      filter: t =>
                        t &&
                        t.color &&
                        t.colors.secondary &&
                        `drop-shadow( 0px 0px 5px ${t.colors.secondary})`,
                    }}
                    className="pointer"
                  />
                </div>
              )}

              <div sx={{ width: 50, textAlign: "center" }}>
                <Styled.h5 sx={{ fontSize: 4 }}>{totalCount}</Styled.h5>
              </div>
              {!hiddenAddQuantity && (
                <div onClick={addProduct} sx={{ marginRight: 2 }}>
                  <IoIosAddCircleOutline
                    sx={{
                      fontSize: 5,
                      fill: "secondary",
                      filter: t =>
                        t &&
                        t.color &&
                        t.colors.secondary &&
                        `drop-shadow( 0px 0px 5px ${t.colors.secondary})`,
                    }}
                    className="pointer"
                  />
                </div>
              )}
              {activeQuantityOptions && (
                <div
                  onClick={() =>
                    addProduct(variants[select].quantityOptions.quantityStep)
                  }
                >
                  <div
                    className="pointer"
                    sx={{
                      variant: "div.flex",
                      justifyContent: "center",
                      width: 30,
                      height: 30,
                      color: "secondary",
                      border: "secondary",
                      borderRadius: "50%",
                      fontFamily: "monospace",
                      fontWeight: "bold",
                    }}
                  >
                    +{variants[select].quantityOptions.quantityStep}
                  </div>
                </div>
              )}
            </div>

            <div
              sx={{
                // boxShadow: "flat",
                width: "100%",
                position: "relative",
                padding: 2,
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
                height: "100%",
              }}
            >
              <div onClick={totalCount > 0 ? addCart : removeCart}>
                <FiShoppingCart
                  sx={{
                    fontSize: 5,
                    // fill: "primary",
                    // backgroundColor: "text",
                    // height: "100%",
                    // width: 50,
                    // padding: 2,
                    // borderRadius: 2,
                    color: "primary",
                    transition: "0.2s",
                    filter: t =>
                      t &&
                      t.color &&
                      t.colors.primary &&
                      `drop-shadow( 0px 0px 4px ${t.colors.primary})`,
                  }}
                  className="pointer"
                />
              </div>
              <span
                sx={{
                  position: "absolute",
                  top: "10%",
                  right: "5px",
                  fontSize: 3,
                  color: "primary",
                  fontWeight: "bold",
                  fontFamily: "body",
                }}
              >
                +
              </span>
            </div>
          </div>
          <Text
            variant="tag"
            sx={{
              position: insideModal ? "absolute" : "fixed",

              maxWidth: 100,
              right: t =>
                t && t.sizes && t.sizes.width1 && insideModal
                  ? 0
                  : [
                      `calc((100vw - ${t.sizes.width1})/2)`,
                      `calc((100vw - ${t.sizes.width2})/2)`,
                      `calc((100vw - ${t.sizes.width3})/2)`,
                    ],
              bottom: 65,
              zIndex: 5,
            }}
          >
            💰 {Money(totalPrice)}
          </Text>
        </div>
      ) : (
        <div>
          <Button onClick={() => setOpenForm(true)} variant="whatsapp">
            <SiWhatsapp sx={{ fontSize: 4, marginRight: 1 }} />
            Pedir info por whatsapp
          </Button>
          <Modal open={openForm} onClose={() => setOpenForm(false)} closeIcon>
            <Text variant="caption">Solicitar información acerca de:</Text>
            <br />
            <div
              sx={{
                variant: "div.flex",
                boxShadow: "flat",
                borderRadius: 2,
                width: "fit-content",
              }}
            >
              <Product product={variants[select]} horizontal money={money} />
            </div>
            <br />
            <Form insideModal productOnly={variants[select]} />
          </Modal>
        </div>
      )}
    </div>
  )
}

export default Controls
