// ** @jsx jsx *
import React, { useState, useRef, useEffect } from "react"
import { jsx, Text, Input, Label, Select } from "theme-ui"
import Product from "../Product.jsx"
import { IoIosArrowDropleft, IoIosArrowDropright } from "react-icons/io"
const FormProduct = ({
  product,
  money,
  extrasCount,
  setVariantIndex,
  formData,
  setFormData,
}) => {
  const validate =
    product.formProduct &&
    product.formProduct.active &&
    product.formProduct.asks &&
    product.formProduct.asks.length > 0

  useEffect(() => {}, [])
  return (
    <>
      {validate && (
        <div sx={{ marginTop: 1, marginBottom: 3 }}>
          {product.formProduct.asks.map((ask, i) => {
            if (ask._type == "productsExtra") {
              return (
                <ProductsExtra
                  key={ask._key}
                  product={product}
                  ask={ask}
                  money={money}
                  extrasCount={extrasCount}
                  setVariantIndex={setVariantIndex}
                />
              )
            } else if (ask._type == "inputText") {
              return (
                <InputText
                  key={ask._key}
                  ask={ask}
                  formData={formData}
                  setFormData={setFormData}
                />
              )
            } else if (ask._type == "options") {
              return (
                <Options
                  key={ask._key}
                  ask={ask}
                  formData={formData}
                  setFormData={setFormData}
                />
              )
            }
          })}
        </div>
      )}
    </>
  )
}
const ProductsExtra = ({
  ask,
  money,
  product,
  extrasCount,
  setVariantIndex,
}) => {
  const [step, setStep] = useState(0)
  const scrollExtra = useRef(null)
  const parts =
    ask.extra && ask.extra.length > 0 ? Math.ceil(ask.extra.length / 2) : 0
  let widthParts =
    scrollExtra &&
    scrollExtra.current &&
    scrollExtra.current.children &&
    scrollExtra.current.children[0].clientWidth
  let stepsWidth = widthParts && widthParts / parts

  const back = () => {
    let newStep = step - 1 >= 0 ? step - 1 : 0
    setStep(newStep)
    scrollExtra.current.scroll(stepsWidth * newStep, 0)
  }
  const next = () => {
    let newStep = step + 1 <= parts ? step + 1 : parts
    setStep(newStep)
    scrollExtra.current.scroll(stepsWidth * newStep, 0)
  }
  return (
    <div sx={{ marginTop: 4, marginBottom: 4 }}>
      {ask.title && <Text variant="caption">{ask.title}:</Text>}
      <div sx={{ position: "relative" }}>
        <div
          ref={scrollExtra}
          sx={{
            variant: "div.flex",

            // width: "100%",
            maxWidth: ["width1", "halfWidth2", "halfWidth3"],
            overflowX: ["auto", "hidden"],
            boxShadow: "pressed",
            scrollBehavior: "smooth",
            // backgroundColor: "backgroundSecondary",
            border: "opaity",
            borderRadius: 2,
            marginTop: 1,
          }}
        >
          {parts > 0 && (
            <>
              <div sx={{ variant: "div.flex" }}>
                {ask.extra.map((_product, i) => {
                  const findExtra = extrasCount.find(
                    ({ extraId }) => extraId == _product.id
                  )
                  const setExtraCount = (count, callBack) => {
                    if (findExtra) {
                      setVariantIndex(findExtra.index, count)
                      if (typeof callBack == "function") {
                        callBack()
                      }
                    }
                  }
                  const noSeparteOptions = ask.separate
                    ? {}
                    : {
                        myProduct: true,
                        initialCount: findExtra && findExtra.count,
                        overwriteSend: setExtraCount,
                        overwriteRemove: callBack => setExtraCount(0, callBack),
                      }
                  return (
                    <div key={`${i}-${_product.id}`} sx={{ marginRight: 1 }}>
                      <Product
                        product={{
                          ..._product,
                          variants: false,
                          quantityOptions: false,
                        }}
                        money={money}
                        horizontal
                        small
                        modal
                        simple
                        {...noSeparteOptions}
                      />
                    </div>
                  )
                })}
                {parts > 1 && (
                  <div
                    sx={{
                      "& .arrow": {
                        position: "absolute",
                        fontSize: 4,
                        top: "50%",
                        transform: "translate(0,-50%)",
                        backgroundColor: "primary",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        width: 35,
                        height: 35,
                        backgroundColor: "background",
                      },
                    }}
                  >
                    {step !== 0 && (
                      <div
                        className="arrow pointer"
                        onClick={back}
                        sx={{
                          left: 0,
                          // boxShadow: "2px 0 5px 5px rgba(0,0,0,0.4) !important",
                          boxShadow: "flatDark",
                          borderRadius: "0 50% 50% 0",
                        }}
                        // onClick={previousImage}
                      >
                        <IoIosArrowDropleft />
                      </div>
                    )}

                    {step < parts - 1 && (
                      <div
                        className="arrow pointer"
                        sx={{
                          right: 0,
                          boxShadow: "flatLight",
                          borderRadius: "50% 0 0 50%",
                        }}
                        onClick={next}
                      >
                        <IoIosArrowDropright />
                      </div>
                    )}
                  </div>
                )}
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  )
}
const InputText = ({ ask, formData, setFormData }) => {
  const [value, setValue] = useState("")

  useEffect(() => {
    let findAsk = {}
    if (formData && formData.length > 0) {
      findAsk = formData.find(({ _key }) => ask._key == _key)
      if (typeof findAsk.index == "number") {
        let newFormData = [...formData]
        newFormData[findAsk.index] = {
          ...newFormData[findAsk.index],
          value,
        }
        setFormData(newFormData)
      }
    }
  }, [value])

  return (
    ask.title && (
      <Label
        sx={{
          variant: "div.flexColumn",
          alignItems: "flex-start",
          marginTop: 4,
          marginBottom: 4,
        }}
      >
        <Text sx={{ marginBottom: 1 }} variant="caption">
          {ask.title}:
        </Text>

        <Input
          value={value}
          onChange={e => setValue(e.target.value)}
          sx={{}}
        ></Input>
      </Label>
    )
  )
}
const Options = ({ ask, formData, setFormData }) => {
  const validateAsk = ask.limit && ask.listOptions && ask.listOptions.length > 1
  const [check, setCheck] = useState(
    validateAsk ? ask.listOptions.map(() => false) : []
  )
  const [value, setValue] = useState(validateAsk ? ask.listOptions[0] : "123")
  const [disabled, setDisabled] = useState(false)
  const [applyDefaultOption, setApplyDefaultOption] = useState(false)
  useEffect(() => {
    let findAsk = {}
    if (formData && formData.length > 0) {
      findAsk = formData.find(({ _key }) => ask._key == _key)
      if (findAsk.index) {
        let newFormData = [...formData]
        newFormData[findAsk.index] = {
          ...newFormData[findAsk.index],
          value: ask.listOptions.filter((ask, i) => check[i]).toString(),
        }
        setFormData(newFormData)
      }
    }
  }, [check])
  useEffect(() => {
    let findAsk = {}
    if (formData && formData.length > 0) {
      findAsk = formData.find(({ _key }) => ask._key == _key)
      if (findAsk) {
        let newFormData = [...formData]
        newFormData[findAsk.index] = {
          ...newFormData[findAsk.index],
          value,
        }
        setFormData(newFormData)
      }
    }
  }, [value])

  return (
    validateAsk && (
      <div
        sx={{
          variant: "div.flexColumn",
          alignItems: "flex-start",
          marginTop: 4,
          marginBottom: 4,
        }}
      >
        {ask.title && (
          <Text sx={{ marginBottom: 2 }} variant="caption">
            {ask.title}:
          </Text>
        )}
        {ask.limit == 1 ? (
          <Select
            sx={{ width: "max-content" }}
            value={value}
            onChange={e => setValue(e.target.value)}
          >
            {ask.listOptions.map(op => {
              return <option>{op}</option>
            })}
          </Select>
        ) : (
          <div sx={{ variant: "div.flex" }}>
            {ask.listOptions.map((op, i) => {
              return (
                <CheckboxComponent
                  op={op}
                  check={check[i]}
                  disabled={disabled}
                  setCheck={value => {
                    let newCheck = [...check]
                    newCheck[i] = value
                    const countCheck = check.filter(c => c == true).length
                    setCheck(newCheck)
                    if (value && countCheck > ask.limit - 2) {
                      setDisabled(true)
                    } else {
                      setDisabled(false)
                    }
                  }}
                />
              )
            })}
          </div>
        )}
        {/* <Input sx={{}}></Input> */}
      </div>
    )
  )
}
const CheckboxComponent = ({ op, check, setCheck, disabled }) => {
  return (
    <Label
      sx={{
        cursor: "pointer",
        variant: "div.flex",
        marginRight: 3,
        boxShadow: check ? "pressedSmall" : disabled ? "" : "flatSmall",
        opacity: !check && disabled ? "0.5" : "",

        borderRadius: 1,
        paddingTop: 1,
        paddingBottom: 1,
        paddingLeft: 2,
        paddingRight: 3,
      }}
    >
      <input
        disabled={!check && disabled}
        type="checkbox"
        checked={check}
        onChange={e => {
          setCheck(e.target.checked)
        }}
      />
      <Text variant="small">{op}</Text>
    </Label>
  )
}
export default FormProduct
