// ** @jsx jsx *
import React, { useState } from "react"
import Img from "gatsby-image"
import { jsx, Styled, Flex, Box } from "theme-ui"
import Link from "./LinkDataGet.jsx"
import Modal from "./Modal.jsx"
import BusinessInfo from "../BusinessInfo.jsx"
const Menu = ({ sanitySetting, sanityCompanyInfo }) => {
  const [openMenu, setOpenMenu] = useState(false)
  return (
    <div
      sx={{
        backgroundColor: "backgroundSecondary",
        display: "flex",
        alignItems: "center",
        width: "100%",
        justifyContent: "center",
      }}
    >
      <div
        sx={{
          display: "flex",
          alignItems: "center",
          width: ["width1"],
          justifyContent: "space-between",
        }}
      >
        <Flex as={Link} to="/" sx={{ alignItems: "center" }}>
          <Box
            sx={{
              backgroundColor: "textBlur",
              padding: "2px",
              borderRadius: 1,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
            p={2}
          >
            <Img
              sx={{
                width: "45px",

                borderRadius: 1,
              }}
              fixed={sanitySetting.logo.asset.fixed}
            />
          </Box>
          <Box p={2}>
            <Styled.h1 sx={{ color: "text", fontSize: 3 }}>
              {sanitySetting.title}
            </Styled.h1>
          </Box>
        </Flex>
        <div className="burgerButton" onClick={() => setOpenMenu(true)}>
          <span sx={{ backgroundColor: "text" }} className="item"></span>
          <span sx={{ backgroundColor: "text" }} className="item"></span>
          <span sx={{ backgroundColor: "text" }} className="item"></span>
        </div>
        <Modal open={openMenu} onClose={() => setOpenMenu(false)} closeIcon>
          <BusinessInfo
            sanityCompanyInfo={sanityCompanyInfo}
            sanitySetting={sanitySetting}
          />
        </Modal>
      </div>
    </div>
  )
}

export default Menu
