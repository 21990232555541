// ** @jsx jsx *
import React, { useContext, useEffect, useState } from "react"
import Img from "gatsby-image"
import { jsx, Styled, Divider, Text, Button, Badge } from "theme-ui"
import slug from "../lib/slug"
import Link from "./ui/LinkDataGet.jsx"
import { CartContext } from "./context/Context"
import LimitText from "../widgets/LimitText.jsx"
// impoRiCheckDoubleFill sx={{fontSize:32}} { FiEdit2 } from"react-icons/fi"
import { FiCheckSquare, FiEye } from "react-icons/fi"
import search from "../lib/search"
import countapi from "countapi-js"
import Modal from "./ui/Modal.jsx"
import ProductMaster from "./productTemplateComponents/ProductMaster"
import SearchProductById from "../lib/SearchProductById.jsx"
import Money from "../lib/Money.jsx"
import ViewCount from "./ui/ViewCount"
const Product = ({
  product,
  money,
  horizontal,
  small,
  card,
  prevPage,
  modal,
  simple,
  initialCount,
  overwriteSend,
  overwriteRemove,
  myProduct,
}) => {
  const [views, setViews] = useState(0)
  const priceNotReduced =
    product.discount &&
    product.discount.active &&
    product.discount.priceNotReduced &&
    Money(product.discount.priceNotReduced)

  const size1 = small ? "100px" : "140px"
  const size2 = small ? "160px" : "220px"
  if (!product) {
    console.log(product)
  }
  let imgSrc = ""

  try {
    imgSrc =
      product.img &&
      product.img.asset &&
      product.img.asset &&
      product.img.asset.fluid
        ? {
            fluid:
              (product &&
                product.img &&
                product.img.asset &&
                product.img.asset.fluid) ||
              {},
          }
        : product && product.img && product.img.asset && product.img.asset.fixed
        ? {
            fixed:
              (product &&
                product.img &&
                product.img.asset &&
                product.img.asset.fixed) ||
              {},
          }
        : {}
  } catch (error) {
    console.log(product)
  }

  //context
  const { cart } = useContext(CartContext)
  let productOrder = search(cart, "id", product.id)
  if (initialCount) {
    productOrder = {
      totalCount: initialCount,
      price: product.price,
      totalPriceWithCurrency: Money(product.price * initialCount),
    }
  }
  const withVariants =
    productOrder.order &&
    productOrder.order[0] &&
    productOrder.order[0].name &&
    productOrder.order[0].tag
      ? true
      : false

  useEffect(() => {
    if (
      typeof window !== "undefined" &&
      window.location &&
      window.location.hostname
    ) {
      countapi
        .get(slug(window.location.hostname), slug(product.id))
        .then(result => {
          setViews(result.value)
        })
        .catch(e => console.log(e))
    }
  }, [])
  let priceMoney = ""
  try {
    priceMoney = Money(product.price)
  } catch (error) {}
  let priceWithCurrency = `💰 ${productOrder.totalPriceWithCurrency}`
  return (
    <LinkOrModal
      to={`/${product.slug}`}
      state={{ prevPage }}
      className="pointer press enter"
      sx={{
        width: horizontal ? size2 : size1,
        height: horizontal ? size1 : size2,
        padding: 2,
        border: productOrder ? (simple ? "" : "opacity") : "",
        boxShadow: card ? (productOrder ? "pressed" : "flat") : "",
        borderRadius: 2,
        display: "flex",
        flexDirection: horizontal
          ? productOrder && withVariants
            ? "row"
            : "column"
          : "column",
        justifyContent: "space-between",
      }}
      product={product}
      initialCount={initialCount}
      modal={modal}
      overwriteSend={overwriteSend}
      overwriteRemove={overwriteRemove}
      myProduct={myProduct}
    >
      <div
        sx={{
          display: "flex",
          flexDirection: horizontal
            ? productOrder && withVariants
              ? simple
                ? "row"
                : "column"
              : "row"
            : productOrder && withVariants
            ? "row"
            : "column",
          alignItems: "flex-start",
          justifyContent: "space-between",
          height: "100%",
          width: horizontal && productOrder && withVariants && "40%",
        }}
      >
        <div
          sx={{
            position: "relative",
            width: horizontal
              ? productOrder
                ? withVariants
                  ? !simple
                    ? "100%"
                    : size1
                  : simple
                  ? "100%"
                  : "70px"
                : size1
              : size1,
            height: horizontal
              ? productOrder && withVariants
                ? simple
                  ? size1
                  : "100%"
                : productOrder
                ? simple && horizontal
                  ? "100%"
                  : "70px"
                : simple && horizontal
                ? "100%"
                : "auto"
              : productOrder && withVariants
              ? "auto"
              : productOrder && !withVariants
              ? "110px"
              : size1,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            // backgroundColor: "soft",
            borderRadius: 2,

            marginBottom: productOrder && withVariants && !simple ? 1 : 2,
          }}
        >
          {imgSrc && (
            <Img
              {...imgSrc}
              sx={{
                width: "calc(100% - 8px)",
                height: horizontal && simple ? "100%" : "auto",
                maxHeight:
                  productOrder && !withVariants
                    ? "110px"
                    : productOrder && withVariants
                    ? !simple
                      ? "70px"
                      : size1
                    : size1,
                borderRadius: 2,
                marginRight: horizontal ? 2 : "",
              }}
              alt={product.title}
              imgStyle={{
                height: "100%",
                borderRadius: 16,
                width: "auto",
                left: "50%",
                transform: "translateX(-50%)",
              }}
            />
          )}
        </div>
        <div
          sx={{
            width: horizontal
              ? productOrder && withVariants
                ? "100%"
                : "40%"
              : "100%",
            variant: simple ? "div.flexColumn" : "",
            justifyContent: simple ? "space-between" : "",
            height: simple ? "100%" : "",
            position: simple ? "relative" : "",
          }}
        >
          <div>
            <Styled.h2 sx={{ fontSize: horizontal ? 0 : 1 }}>
              <LimitText
                text={product.title}
                limitText={horizontal ? 20 : 40}
                noToggle
              />
            </Styled.h2>
            <Styled.h6
              sx={{
                fontSize: small ? 0 : "",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              {priceNotReduced ? (
                <div>
                  <span sx={{ fontSize: 1 }}>{priceMoney}</span>{" "}
                  <span
                    sx={{
                      fontSize: 0,
                      textDecoration: "line-through",
                      opacity: 0.5,
                    }}
                  >
                    {priceNotReduced}
                  </span>
                </div>
              ) : (
                priceMoney
              )}
              {productOrder && !withVariants && (
                <Badge variant="secondary">x {productOrder.totalCount}</Badge>
              )}
              {!horizontal && !productOrder && views && (
                // <span
                //   sx={{
                //     fontFamily: "monospace",
                //     display: "flex",
                //     justifyContent: "center",
                //     alignItems: "center",
                //     marginLeft: "auto",
                //   }}
                // >
                //   {views} <FiEye sx={{ marginLeft: 1, color: "text" }} />
                // </span>
                <ViewCount
                  hostname={
                    (typeof window !== "undefined" &&
                      window.location &&
                      window.location.hostname) ||
                    false
                  }
                  id={product.id}
                />
              )}
            </Styled.h6>
          </div>
          {(productOrder &&
            productOrder.order &&
            productOrder.order[0] &&
            simple) ||
            (initialCount ? (
              <div sx={{ variant: "div.flexColumn" }}>
                <Text
                  variant="tag"
                  sx={{
                    width: "max-content",
                    fontSize: "10px",
                    display: "flex",
                    padding: "1px",
                  }}
                >
                  {priceWithCurrency}
                </Text>
                <Button
                  sx={{
                    position: "absolute",
                    top: -2,
                    left: "-22px",
                    padding: "3px",
                  }}
                  variant="secondary"
                >
                  <FiCheckSquare sx={{ fontSize: 1 }} />
                </Button>
              </div>
            ) : (
              ""
            ))}
        </div>
      </div>
      {productOrder && productOrder.order && productOrder.order[0] && !simple && (
        <div>
          <div>
            {productOrder.order[0].name && (
              <Text variant="caption"> {productOrder.order[0].name}: </Text>
            )}
            <div sx={{ overflowY: "auto", maxHeight: horizontal ? 70 : 80 }}>
              {withVariants &&
                productOrder.order.map((variant, i) => {
                  return (
                    <Button
                      key={`variants-resume-${i}`}
                      sx={{
                        marginBottom: 1,
                        marginRight: 1,
                        fontSize: 0,
                        padding: 1,
                      }}
                      variant="outlined"
                    >
                      {variant.tag}
                      <Badge
                        variant="secondary"
                        ml={variant.tag ? 1 : 0}
                        mr={0}
                      >
                        {variant.count}
                      </Badge>
                    </Button>
                  )
                })}
            </div>
          </div>
          <div>
            <Divider />
            <div
              sx={{
                display: "flex",
                width: "100%",
                justifyContent: "space-between",
              }}
            >
              <Text variant="tag" sx={{ width: "fit-content" }}>
                {priceWithCurrency}
              </Text>
              <Button variant="secondary">
                <FiCheckSquare sx={{ fontSize: 3 }} />
              </Button>
            </div>
          </div>
        </div>
      )}
    </LinkOrModal>
  )
}
const LinkOrModal = props => {
  const { modal, children } = props
  return modal ? (
    <ProductModal
      {...props}
      product={props.product}
      initialCount={props.initialCount}
      overwriteSend={props.overwriteSend}
      overwriteRemove={props.overwriteRemove}
      myProduct={props.myProduct}
    >
      {children}
    </ProductModal>
  ) : (
    <Link {...props}>{children}</Link>
  )
}
const ProductModal = props => {
  const [openProductModal, setOpenProductModal] = useState(false)
  const product = props.myProduct
    ? props.product
    : SearchProductById(props.product.id)
  const overwriteSend =
    typeof props.overwriteSend == "function"
      ? count => props.overwriteSend(count, () => setOpenProductModal(false))
      : false
  const overwriteRemove =
    typeof props.overwriteRemove == "function"
      ? count => props.overwriteRemove(count, () => setOpenProductModal(false))
      : false
  return (
    <>
      <div {...props} onClick={() => setOpenProductModal(true)}>
        {props.children}
      </div>
      <Modal
        open={openProductModal}
        onClose={() => setOpenProductModal(false)}
        closeIcon
        maxWidth={[300, 600, 900]}
      >
        <ProductMaster
          product={product}
          initialCount={props.initialCount}
          insideModal
          overwriteSend={overwriteSend}
          overwriteRemove={overwriteRemove}
          onComplete={() => setOpenProductModal(false)}
        />
      </Modal>
    </>
  )
}
export default Product
