// ** @jsx jsx *
import React, { useState, useEffect, useRef } from "react"
import { jsx, Flex, Box } from "theme-ui"
import { MdRestore } from "react-icons/md"
import SnackBar from "./ui/SnackBar"
import { useStaticQuery, graphql, Link } from "gatsby"
const CategoriesSnacks = ({ overflowX, newCategories, removeViewAll }) => {
  const { allSanityCategory } = useStaticQuery(graphql`
    query allCategory {
      allSanityCategory(sort: { order: ASC, fields: [title] }) {
        nodes {
          id
          title
          slug
          hideIndex
          icon {
            asset {
              fixed(width: 25, height: 25) {
                base64

                width
                height
                src
                srcSet
                srcWebp
                srcSetWebp
              }
            }
          }
        }
      }
    }
  `)

  const [categories, setCategories] = useState(allSanityCategory.nodes)
  let categorySnacks = useRef(null)
  useEffect(() => {
    if (newCategories) {
      let validateNewCategories = []
      newCategories.forEach(category => {
        if (category && category.id) {
          validateNewCategories.push(category)
        }
      })
      setCategories(validateNewCategories)
    }
  }, [])
  return overflowX ? (
    <>
      {categories.length > 1 && (
        <Box
          p={3}
          sx={{
            borderBottomLeftRadius: 2,
            borderBottomRightRadius: 0,
            boxShadow: "shadowInset",
            width: "100%",
            height: "100%",
            maxWidth: ["width1", "width2", "width3"],
            overflowX: "auto",
          }}
        >
          <Flex
            ref={categorySnacks}
            sx={{
              width: "max-content",
            }}
          >
            {categories.length > 1 && !removeViewAll && (
              <Link to="/" sx={{ color: "text" }}>
                <SnackBar
                  text="Ver todo"
                  Icon={MdRestore}
                  active={
                    typeof window !== "undefined" &&
                    window.location.pathname == "/"
                  }
                />
              </Link>
            )}
            {categories.map(({ id, title, hideIndex, icon, slug }, i) => {
              return (
                !hideIndex && (
                  <Link key={`${id}-overflowX`} to={`/${slug}`}>
                    <SnackBar
                      index={i}
                      refParent={categorySnacks}
                      active={
                        typeof window !== "undefined" &&
                        window.location.pathname.replace("/", "") == slug
                      }
                      text={title}
                      fixedImg={
                        icon && icon.asset && icon.asset.fixed
                          ? icon.asset.fixed
                          : ""
                      }
                    />
                  </Link>
                )
              )
            })}
          </Flex>
        </Box>
      )}
    </>
  ) : (
    <>
      {categories.length > 0 && (
        <>
          {categories.map(({ id, title, icon, slug }, i) => {
            return (
              <div key={id} sx={{ marginBottom: 2 }}>
                <Link to={`/${slug}`}>
                  <SnackBar
                    index={i}
                    refParent={categorySnacks}
                    active={
                      typeof window !== "undefined" &&
                      window.location.pathname.replace("/", "") == slug
                    }
                    text={title}
                    fixedImg={
                      icon && icon.asset && icon.asset.fixed
                        ? icon.asset.fixed
                        : false
                    }
                  />
                </Link>
              </div>
            )
          })}
        </>
      )}
    </>
  )
}

export default CategoriesSnacks
