// ** @jsx jsx *
import React from "react"
import { jsx, Button, Text } from "theme-ui"
const Variants = ({ variants, select, setSelect }) => {
  return (
    <>
      {variants.length > 0 && variants[0].tag && (
        <div sx={{ marginBottom: 16 }}>
          {variants[0].variants.name && (
            <div>
              <Text variant="caption" mb={2}>
                {variants[0].variants.name}:
              </Text>
            </div>
          )}
          <div sx={{ display: "flex", flexWrap: "wrap" }}>
            {variants.map((variant, i) => {
              return (
                !variant.hiddenExtra && (
                  <Button
                    key={i}
                    onClick={() => setSelect(i)}
                    className="pointer"
                    variant={select == i ? "primary" : "outlined"}
                    sx={{
                      zIndex: 1,
                      position: "relative",
                      marginRight: 2,
                      marginBottom: 2,
                      minWidth: 60,
                    }}
                  >
                    {variant.tag}
                    {variant.count > 0 && (
                      <div
                        sx={{
                          position: "absolute",
                          right: -10,
                          top: -10,
                          // transform: "translateX(50%)",
                          backgroundColor: "secondary",
                          fontSize: 1,
                          // fontWeight: "bold",
                          color: "textSecondary",
                          fontFamily: "monospace",
                          borderRadius: "50%",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          width: 20,
                          height: 20,
                        }}
                      >
                        {variant.count}
                      </div>
                    )}
                  </Button>
                )
              )
            })}
          </div>
          {/* <Divider /> */}
        </div>
      )}
    </>
  )
}

export default Variants
